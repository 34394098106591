import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from "react-cookie"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import ImageSection from "../components/imageSection/imageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const about1 = "<p>En México hay más de 4 millones de ellas que generan el 72% del empleo y contribuyen el 52% del PIB. Estas son las empresas que hacen que la nación sea competitiva en el mundo, esta es la gente que crea un impacto y fomenta el crecimiento en el país.</p>";
const about2 = "<p>Sin embargo sabemos que no es fácil. Las PyMEs enfrentan innumerables problemas día a día. El 80% de ellas \"mueren\" debido a una gestión financiera ineficiente o inexistente, por problemas para gestionar los flujos de caja, salidas inesperadas de efectivo y no se diga la dificultad para obtener un financiamiento. La ansiedad y estrés por factores financieros persiste en todos los emprendedores.</p>";
const about3 = "<p>Es por eso que en EVVA estamos reinventando la banca empresarial mediante la creación de experiencias financieras personalizadas, transparentes y simples; con la ambición de ayudar a los emprendedores en México a tomar mejores decisiones sobre su dinero.</p>";

const manifesto1 = "<p><strong>Emprendimiento:</strong> nuestra inspiración diaria. Nos enfocamos en las necesidades financieras de los emprendedores para que ellos se enfoquen en lo que realmente necesitan: su empresa.</p>";
const manifesto2 = "<p><strong>Veracidad:</strong> ser transparente y franco con nuestros clientes. Desaparecemos las comisiones ocultas y tarifas complicadas.</p>";
const manifesto3 = "<p><strong>Velocidad:</strong> Todo está cambiando constantemente. El ser ágil y adaptable es la clave para tener éxito. Creamos experiencias financieras ágiles con el objetivo final de hacer que las pequeñas empresas crezcan al siguiente nivel.</p>";
const manifesto4 = "<p><strong>Accesibilidad:</strong> siendo inclusivos. Nuestros servicios están abiertos a todos los empresarios que quieran crear un impacto y contribuir a la economía del país..</p>";

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
const footerDesc = "";
const contentMaxWidth = "980px";

const Nosotros = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="¡Gracias por tu interés!" subtitle="Por ahora el acceso a la plataforma es sólo por invitación. Regístrate y solicita tu acceso cuanto antes." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="nosotros" title="Acerca de EVVA" description="Somos un equipo internacional de creadores, apasionados por la innovación en tecnología financiera y su impacto social."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/>}
      {!cookies.consent &&
      <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler} />
      }
      <MainSection title="Nosotros" position="left" image="hero-about.jpg">
        <AppBar pathname={appState.path} />
      </MainSection>
      <PageSection title="EVVA comenzó con una simple pregunta..." maxWidth={contentMaxWidth}>
        <Card
            title="¿Por qué es tan complicado para las PyMEs tener acceso a servicios financieros?"
            description={`${about1}${about2}${about3}`}
            descriptionColor="#363636"
            padding
        />
      </PageSection>
      <ImageSection
        title="EVVA proviene de nuestro manifesto"
        description={`${manifesto1}${manifesto2}${manifesto3}${manifesto4}`}
        position="left"
        image="evva-stats-phone.png"
        maxWidth={contentMaxWidth}
        bgColor="#CEd6E0"
        pathname={appState.lang}
      />
      <PageSection title="Nos respaldan">
        <div style={{margin: "auto", maxWidth: contentMaxWidth}}>
          <img src="../logo-partners-v2.png" width="100%" alt="ours partners"/>
        </div>
      </PageSection>
      <SecondarySection
        title="¿En dónde estamos?"
        shortDescription="Somos un equipo internacional de creadores, apasionados por la innovación en tecnología financiera y su impacto social."
        description="<p>Ubicados en Oslo Noruega y CDMX México</p>"
        position="center"
        image="evva-offices.jpg"
      />
      {/*
      <PageSection maxColumns="2" title="Nuestro equipo de asesores">
        <Card
            subtitle="Yook Shee"
            description="<p>Líder en innovación tecnológica bancaria, experto en desarrollo de productos fintech con 20 años de experiencia trabajando en empresas como BNP Paribas, EVRY y Klarna</p>"
            descriptionColor="#363636"
            image="advisor-1.jpg"
            link="https://www.linkedin.com/in/yookx/"
            linkLabel="Perfil en linkedIn"
            linkIcon="in"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Susanne Hannestad"
            description="<p>Susanne es actualmente CEO de Fintech Mundi, una compañía que ayuda a las empresas de tecnología financiera de alto potencial a desarrollar sus ideas, comercializar sus productos y hacer crecer sus negocios a nivel internacional</p>"
            descriptionColor="#363636"
            image="advisor-2.jpg"
            link="https://www.linkedin.com/in/susannehannestad/"
            linkLabel="Perfil en linkedIn"
            linkIcon="in"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Borghild Holen"
            description="<p>Vicepresidente senior con experiencia en banca e inversiones internacionales. Cuenta una gran experiencia en el área de finanzas internacionales y negocios en mercados emergentes</p>"
            descriptionColor="#363636"
            image="advisor-3.jpg"
            link="https://www.linkedin.com/in/borghild-holen-7256a746/"
            linkLabel="Perfil en linkedIn"
            linkIcon="in"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Magne Angelshaug"
            description="<p>Magne actualmente trabaja en el desarrollo de nuevos modelos de negocio, así como nuevos productos digitales en una de la instituciones bancarias más reconocidas en Noruega, Sbanken</p>"
            descriptionColor="#363636"
            image="advisor-4.jpg"
            link="https://www.linkedin.com/in/magne-angelshaug-2062041/"
            linkLabel="Perfil en linkedIn"
            linkIcon="in"
            horizontal
            padding
            shadow
        />
      </PageSection>
        */}
      <SecondarySection
        title="¿Quieres conocernos mejor?"
        shortDescription="Déjanos ayudarte a crecer juntos."
        description="<p>Contáctanos directamente en: <a href='mailto:info@evvafinanzas.com'>info@evvafinanzas.com<a/></p>"
        position="center"
        image="evva-contact.jpg"
        actionLabel="Regístrate, es gratis"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUp"
      />
    </Layout>
  )
};

export default Nosotros